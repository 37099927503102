import "../styles/styles.css";

export default function Login() {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-12 gap-0">
      <div className="col-span-1 lg:col-span-8 p-4 xl:p-12 mt-6 w-full md:w-2/4 mx-auto">
        <a
          href="/"
          title="Kutty Home Page"
          className="flex items-center justify-start"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
            viewBox="0 0 24 24"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
          </svg>
          <span className="sr-only">Alarmo</span>
        </a>
        <h1 className="text-xl text-left font-light mt-6 mb-4 text-gray-800">
          Log in to your account
        </h1>
        <form className="space-y-4 pb-1">
          <label className="block">
            <span className="text-xs text-gray-700 font-medium block mb-1">
              Your Email
            </span>
            <input
              className="form-input"
              type="email"
              placeholder="Ex. james@bond.com"
              inputmode="email"
              required
            />
          </label>
          <label className="block">
            <span className="text-xs text-gray-700 font-medium block mb-1">
              Your Password
            </span>
            <input
              className="form-input"
              type="password"
              placeholder="••••••••"
              required
            />
          </label>
          <div className="flex justify-between items-center">
            <label className="flex items-center">
              <input type="checkbox" className="form-checkbox" />
              <span className="ml-2 text-xs text-gray-700 cursor-pointer font-medium block">
                Remember me
              </span>
            </label>
            <input type="submit" className="btn btn-primary" value="Login" />
          </div>
        </form>
        <div className="my-6 space-y-2">
          <p className="text-xs text-gray-600">
            Don't have an account?{" "}
            <a href="#" className="text-purple-700 hover:text-black">
              Create an account
            </a>
          </p>
          <a
            href="#"
            className="block text-xs text-purple-700 hover:text-black"
          >
            Forgot password?
          </a>
          <a
            href="#"
            className="block text-xs text-purple-700 hover:text-black"
          >
            Privacy & Terms
          </a>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-4">
        <img
          src="https://images.unsplash.com/photo-1531548731165-c6ae86ff6491?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"
          alt="3 women looking at a laptop"
          className="w-full object-cover h-64 min-h-full bg-gray-100"
          loading="lazy"
        />
      </div>
    </section>
  );
}
